import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Layout from "../components/layout"

const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 32px auto;
`

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const image = getImage(post.frontmatter.cover_image)

  return (
    //   class for this page is in global
    <Layout>
      <OuterDiv className="blogPostWrapper">
        <div className="blogPostImageWrapper">
          <GatsbyImage image={image} alt={post.frontmatter.title} />
        </div>
        <h1>{post.frontmatter.title}</h1>
        <small>{post.frontmatter.date}</small>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </OuterDiv>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        cover_image {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, breakpoints: [360, 768, 1100], quality: 80)
          }
        }
      }
    }
  }
`
